<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-02 09:45:05
 * @LastEditTime: 2020-05-07 15:00:34
 -->
<template>
	<el-tabs v-model="activeName">
		<el-tab-pane name="1" label="部门查询" lazy v-if="permissionControl('DepartmentQuery')"><QueryOrg pageName="DepartmentQuery" /></el-tab-pane>
		<el-tab-pane name="2" label="个人查询" lazy v-if="permissionControl('PersonalQuery')"><QueryStaff pageName="PersonalQuery" /></el-tab-pane>
	</el-tabs>
</template>
<script>
import QueryOrg from './queryOrg';
import QueryStaff from './queryStaff';
export default {
	components: { QueryOrg, QueryStaff },
	props: { pageName: {} },
	data() {
		return {
			activeName: '1'
		};
	},
	mounted() {
		// 设置tab默认
		this.setActiveTab(this.pageName, [
			{ name: '1', key: 'DepartmentQuery' },
			{ name: '2', key: 'PersonalQuery' }
		]);
	}
};
</script>
<style lang="scss" scoped></style>
