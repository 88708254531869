<!--
 * @Author: 卢均锐morye
 * @Date: 2020-03-25 21:27:17
 * @LastEditTime: 2020-04-10 16:26:28
 -->
<template>
	<div v-loading="loading">
		<el-form
			ref="searchForm"
			class="searchForm"
			inline
			size="small"
			style="background-color: #fff; margin-bottom: 0px; padding-top: 10px; padding-bottom: 0px"
		>
			<el-form-item label="年份">
				<el-date-picker
					v-model="year"
					type="year"
					value-format="yyyy"
					placeholder="请选择对应年度"
					:picker-options="pickerOptionsStart"
				></el-date-picker>
			</el-form-item>
			<el-row v-if="showEdit" style="text-align: right; z-index: 10; float: right" v-show="!editStatus">
				<el-button type="primary" size="small" v-show="!editStatus" @click="editStatus = true">编辑</el-button>
			</el-row>
			<el-row style="text-align: right; float: right" v-show="editStatus">
				<el-button size="small" @click="_cancle">取消</el-button>
				<el-button type="primary" size="small" @click="_save">提交</el-button>
			</el-row>
		</el-form>
		<el-form inline ref="form" label-width="120px" size="small">
			<el-row>
				<el-col :span="12">
					<el-form-item label="组织绩效系数">
						<Etcbox
							:type="1"
							:isInfo="!editStatus"
							:defaultJobEtcList="orgLevelList"
							:addBtnString="'添加组织绩效系数等级'"
							:numberRemove="5"
						/>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="员工绩效系数">
						<Etcbox
							:type="2"
							:isInfo="!editStatus"
							:defaultJobEtcList="staffLevelList"
							:addBtnString="'添加员工绩效系数等级'"
							:numberRemove="3"
						/>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>
<script>
import { bounsPerformanceValueList, bounsPerformanceValueSave } from '@/api/remuneration';
import Etcbox from '../components/EtcBox';
export default {
	components: { Etcbox },
	data() {
		return {
			loading: false,
			editStatus: false,
			showEdit: false,
			year: String(new Date().getFullYear()),
			orgLevelList: [],
			staffLevelList: [],
			valorgLevelList: [],
			valstaffLevelList: [],
			pickerOptionsStart: {
				disabledDate(time) {
					return time.getTime() > new Date(new Date().toLocaleDateString()).getTime();
				}
			}
		};
	},
	methods: {
		_getList() {
			bounsPerformanceValueList({ year: this.year }).then((res) => {
				if (!res.orgLevelList || !res.staffLevelList || (res.orgLevelList.length <= 0 && res.staffLevelList.length <= 0)) {
					this.showEdit = false;
				} else {
					this.showEdit = true;
				}
				this.orgLevelList = res.orgLevelList;
				this.staffLevelList = res.staffLevelList;
				this.valorgLevelList = JSON.parse(JSON.stringify(res.orgLevelList));
				this.valstaffLevelList = JSON.parse(JSON.stringify(res.staffLevelList));
			});
		},
		_save() {
			for (const key in this.orgLevelList) {
				if (this.orgLevelList[key].performanceCoefficient > 1) {
					this.$message.error('组织绩效系数不能大于1');
					return false;
				}
			}
			for (const key in this.staffLevelList) {
				if (this.staffLevelList[key].performanceCoefficient > 1) {
					this.$message.error('员工绩效系数不能大于1');
					return false;
				}
			}
			this.$confirm('是否保存当前设置', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.loading = true;
					const _obj = {
						orgLevelList: this.orgLevelList,
						staffLevelList: this.staffLevelList
					};

					bounsPerformanceValueSave(_obj).then((res) => {
						this.loading = false;
						if (res.code == 0) {
							this.editStatus = false;
							this._getList();
							this.$message.success('保存成功');
						} else {
							this.$message.error(res.message);
						}
					});
				})
				.catch(() => {});
		},
		_cancle() {
			this.editStatus = false;
			this.orgLevelList = JSON.parse(JSON.stringify(this.valorgLevelList));
			this.staffLevelList = JSON.parse(JSON.stringify(this.valstaffLevelList));
		}
	},
	watch: {
		year(newVal) {
			if (!newVal) {
				this.$message.error('请选择年度');
				return false;
			}
			this.year = newVal;
			this._getList();
		}
	},
	mounted() {
		this._getList();
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
</style>
