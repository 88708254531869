<template>
	<div v-loading="loading">
		<el-form inline ref="form" class="searchForm" size="small" style="background-color: #fff; margin-bottom: 15px; padding-top: 15px">
			<el-form-item label="年份">
				<el-date-picker
					style="width: 160px"
					type="year"
					value-format="yyyy"
					format="yyyy"
					v-model="year"
					placeholder="年份"
					clearable
					:disabled="editStatus"
					:picker-options="pickerOptionsStart"
				>
				</el-date-picker>
			</el-form-item>
			<el-button type="primary" size="small" v-show="!editStatus" style="margin-left: 5px" icon="el-icon-search" @click="_getDetail"
				>查询</el-button
			>
			<el-row style="text-align: right; float: right; padding-right: 22px">
				<el-button
					type="primary"
					icon="el-icon-coin"
					size="small"
					v-show="!currentForm.calculationStatus && currentForm.grantType != 1 && !editStatus && currentForm.totalBonusPackage > 0"
					@click="_calculation"
					>开始计算</el-button
				>
				<el-button
					type="primary"
					icon="el-icon-edit"
					size="small"
					v-show="!editStatus && !currentForm.calculationStatus && currentForm.grantType != 1"
					@click="editStatus = true"
					v-if="permissionControlBtns(pageName, 'Edit')"
					>编辑</el-button
				>
			</el-row>
		</el-form>
		<div v-if="!editStatus" style="position: relative">
			<div class="showForm">
				全年薪酬总额（<span class="showForm_red">{{ currentForm.totalAnnualSalary }}元</span>） = 营业收入 （<span class="showForm_red"
					>{{ currentForm.taking }}元</span
				>）*人工成本比例 （<span class="showForm_green">{{ currentForm.laborCostRatio }}%</span>）
			</div>
			<div class="showForm">
				全年可分配奖金总包（<span class="showForm_red">{{ currentForm.totalBonusPackage }}元</span>） = 全年薪酬总额 （<span
					class="showForm_red"
					>{{ currentForm.totalAnnualSalary }}元</span
				>）- 实发薪酬总额 （<span class="showForm_red">{{ currentForm.totalPaidSalary }}元</span>）- 额外金额 （<span class="showForm_red"
					>{{ currentForm.extraBonuses }}元</span
				>）
			</div>
			<div class="showForm">
				利润总额（<span class="showForm_red">{{ currentForm.totalProfit }}元</span>）
			</div>
			<div v-if="currentForm.calculationStatus" class="previewBtn" @click="_handleToPage('RemunerationAnnualPreview')">
				<img src="../../../../assets/remuneration/发放预览@3x.png" alt="" />
				发放预览
			</div>
			<p v-if="currentForm.grantType && currentForm.grantType == 1" style="color: #c8c8c8; font-size: 14px">
				发放日期：{{ currentForm.grantDateTime.slice(0, 10) }}
			</p>
			<img
				v-if="currentForm.grantType && currentForm.grantType == 1"
				src="../../../../assets/remuneration/已发放@3x.png"
				alt=""
				class="showIcon"
			/>
		</div>
		<el-form v-else :model="editForm" inline ref="editForm" size="small" class="currentForm" :rules="rules">
			<el-row>
				<span class="currentForm_text">全年薪酬总额（M）= </span>
				<el-form-item prop="taking">
					<el-input onkeyup="value=value.replace(/[^\d^\.]/g,'')" v-model="editForm.taking" placeholder="请输入营业收入">
						<template slot="append">元</template>
					</el-input>
				</el-form-item>
				<span> * </span>
				<el-form-item prop="laborCostRatio">
					<el-input onkeyup="value=value.replace(/[^\d^\.]/g,'')" v-model="editForm.laborCostRatio" placeholder="请输入人工成本比例">
						<template slot="append">%</template>
					</el-input>
				</el-form-item>
			</el-row>
			<el-row>
				<span class="currentForm_text">全年可分配奖金总包（N）= 全年薪酬总额（M）- 实发薪酬总额（X）- </span>
				<el-form-item prop="extraBonuses">
					<el-input onkeyup="value=value.replace(/[^\d^\.]/g,'')" v-model="editForm.extraBonuses" placeholder="请输入额外奖金">
						<template slot="append">元</template>
					</el-input>
				</el-form-item>
			</el-row>
			<el-row>
				<span class="currentForm_text">利润总额 = </span>
				<el-form-item prop="totalProfit">
					<el-input onkeyup="value=value.replace(/[^\d^\.]/g,'')" v-model="editForm.totalProfit" placeholder="请输入利润总额">
						<template slot="append">元</template>
					</el-input>
				</el-form-item>
			</el-row>
			<el-row>
				<el-button size="small" @click="_abort">取消</el-button>
				<el-button type="primary" size="small" @click="_save">提交</el-button>
			</el-row>
		</el-form>
	</div>
</template>
<script>
import { bounsBounsCalcuationConfigDetail, bounsCalcuationConfigSubmit, bounsConfirmCalculation } from '@/api/remuneration';
export default {
	props: { pageName: {} },
	data() {
		return {
			loading: false,
			year: '',
			editStatus: false,
			currentForm: {},
			editForm: {},
			rules: {
				taking: [{ required: true, message: '请输入营业收入', trigger: 'blur' }],
				laborCostRatio: [{ required: true, message: '请输入人工成本比例', trigger: 'blur' }],
				extraBonuses: [{ required: true, message: '请输入额外奖金', trigger: 'blur' }],
				totalProfit: [{ required: true, message: '请输入利润总额', trigger: 'blur' }]
			},
			pickerOptionsStart: {
				disabledDate(time) {
					return time.getTime() > new Date(new Date().toLocaleDateString()).getTime();
				}
			}
		};
	},
	methods: {
		_getDetail() {
			if (!this.year) {
				this.$message.error('请先选择年份');
				return false;
			}
			const _params = { year: this.year };

			this.loading = true;
			bounsBounsCalcuationConfigDetail(_params).then((res) => {
				this.loading = false;
				this.currentForm = res;
				this.editForm = res;
			});
		},
		_save() {
			this.editForm.year = this.year;
			this.$refs['editForm'].validate((valid) => {
				if (valid) {
					if (!(this.editForm.laborCostRatio >= 0 && this.editForm.laborCostRatio <= 100)) {
						this.$message.error('人工成本比例只能为0-100');
						return false;
					}
					if (this.editForm.extraBonuses > this.editForm.taking * (this.editForm.laborCostRatio / 100)) {
						this.$message.error('额外金额不能超过全年薪酬总额');
						return false;
					}
					this.loading = true;
					bounsCalcuationConfigSubmit(this.editForm).then((res) => {
						this.loading = false;
						if (res.code == 0) {
							this.$message.success('修改成功');
							this.editStatus = false;
							this._getDetail();
						}
					});
				}
			});
		},
		_abort() {
			this.editStatus = false;
			this.editForm = this.currentForm;
		},
		_handleToPage(name) {
			this.$router.push({ name, query: { year: this.year, grantType: this.currentForm.grantType } });
		},
		_calculation() {
			this.loading = true;
			bounsConfirmCalculation({ year: this.year }).then((res) => {
				this.loading = false;
				if (res.code == 0) {
					this.$message.success('计算成功');
					this._getDetail();
				}
			});
		}
	},
	mounted() {
		this.year = String(new Date().getFullYear());
		this._getDetail();
	},
	watch: {
		year(newVal) {
			this.year = newVal || '';
			// this._getDetail();
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
.showForm {
	margin-bottom: 20px;
	.showForm_red {
		color: #f56c6c;
	}
	.showForm_green {
		color: #67c23a;
	}
}
.currentForm {
	.currentForm_text {
		line-height: 30px;
	}
}
.previewBtn {
	font-size: 14px;
	color: #409eff;
	cursor: pointer;
	margin-bottom: 20px;
	img {
		width: 20px;
		vertical-align: middle;
	}
}
.showIcon {
	position: absolute;
	right: 0;
	top: 10px;
	width: 85px;
}
</style>
