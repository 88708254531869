<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-07 15:56:31
 * @LastEditTime: 2020-04-07 16:04:39
 -->
<template>
	<el-tabs v-model="activeName" class="app-container">
		<el-tab-pane label="年终奖查询" name="1" lazy v-if="permissionControl('YearEndBonusQuery')"
			><Query ref="query" pageName="YearEndBonusQuery"
		/></el-tab-pane>
		<el-tab-pane label="部门价值系数分配" name="2" lazy v-if="permissionControl('ValueCoefficientDistribution')"
			><Org pageName="ValueCoefficientDistribution"
		/></el-tab-pane>
		<el-tab-pane label="岗位价值系数配置" name="3" lazy v-if="permissionControl('PostValueAllocation')"
			><Office pageName="PostValueAllocation"
		/></el-tab-pane>
		<el-tab-pane label="绩效系数配置" name="4" lazy v-if="permissionControl('PerformanceCoefficientConfiguration')"
			><PerformanceSetting pageName="PerformanceCoefficientConfiguration"
		/></el-tab-pane>
		<el-tab-pane label="奖金计算配置" name="5" lazy v-if="permissionControl('BonusCalculationConfiguration')"
			><Setting pageName="BonusCalculationConfiguration"
		/></el-tab-pane>
	</el-tabs>
</template>
<script>
import Query from './page/query';
import Org from './page/org';
import Office from './page/office';
import PerformanceSetting from './page/performanceSetting';
import Setting from './page/setting';
export default {
	components: { Query, Org, Office, PerformanceSetting, Setting },
	data() {
		return {
			activeName: '1'
		};
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			`${this.$route.name}Data`,
			JSON.stringify({
				activeName: this.activeName,
				childActive: this.$refs.query && this.$refs.query.activeName
			})
		);
		next();
	},
	mounted() {
		const data = JSON.parse(sessionStorage.getItem(`${this.$route.name}Data`));

		if (data?.activeName) {
			this.activeName = data.activeName;
		}
		if (data?.childActive) {
			// eslint-disable-next-line no-unused-expressions
			this.$refs.query && (this.$refs.query.activeName = data.childActive);
		}
		// 设置tab默认
		this.setActiveTab(this.$route.name, [
			{ name: '1', key: 'YearEndBonusQuery' },
			{ name: '2', key: 'ValueCoefficientDistribution' },
			{ name: '3', key: 'PostValueAllocation' },
			{ name: '4', key: 'PerformanceCoefficientConfiguration' },
			{ name: '5', key: 'BonusCalculationConfiguration' }
		]);
	}
};
</script>
