<template>
	<div v-loading="loading">
		<el-form
			:model="searchForm"
			inline
			ref="form"
			class="searchForm"
			size="small"
			style="background-color: #fff; margin-bottom: 0px; padding-top: 10px; padding-bottom: 0px"
		>
			<!--<el-form-item label="职族">
            <el-input
                clearable
                v-model="showfamilyName"
                :disabled="editStatus"
                @focus="dialogTableVisible = true"
                placeholder="请选择职族"
                @clear="_clear" />
        </el-form-item>
        <el-form-item label="职类">
            <el-input
                clearable
                v-model="showclassName"
                :disabled="editStatus"
                @focus="dialogTableVisible = true"
                placeholder="请选择职类"
                @clear="_clear" />
        </el-form-item>!-->
			<el-form-item label="岗位">
				<el-select placeholder="请选择岗位" style="width: 100%" filterable size="small" clearable v-model="searchForm.jobId">
					<el-option v-for="item in jobList" :key="item.id" :label="item.jobOrOfficeName" :value="item.id"></el-option>
				</el-select>
				<!-- <el-input
                clearable
                v-model="showjobName"
                :disabled="editStatus"
                @focus="dialogTableVisible = true"
                placeholder="请选择岗位"
                @clear="_clear" />!-->
			</el-form-item>
			<el-button type="primary" size="small" v-show="!editStatus" style="margin-left: 5px" icon="el-icon-search" @click="_getList(1)"
				>查询</el-button
			>
			<el-row style="text-align: right; float: right">
				<el-button
					type="primary"
					size="small"
					icon="el-icon-edit"
					v-if="permissionControlBtns(pageName, 'Edit')"
					v-show="!editStatus"
					@click="editStatus = true"
					>编辑</el-button
				>
				<el-button size="small" @click="_cancel" v-show="editStatus">取消</el-button>
				<el-button type="primary" size="small" @click="_save" v-show="editStatus">提交</el-button>
			</el-row>
		</el-form>
		<el-table :data="list" stripe border>
			<el-table-column type="index" label="序号" show-overflow-tooltip width="200" align="center"> </el-table-column>
			<el-table-column prop="jobName" label="岗位" show-overflow-tooltip align="center"> </el-table-column>
			<el-table-column prop="valueCoefficient" label="岗位价值系数" align="center">
				<template v-slot="{ row }">
					<span v-if="!editStatus">{{ row.valueCoefficient || 0 }}</span>
					<el-input v-else onkeyup="value=value.replace(/[^\d^\.]/g,'')" v-model="row.valueCoefficient" placeholder="请输入"></el-input>
				</template>
			</el-table-column>
			<el-table-column prop="operatorName" label="修改人" show-overflow-tooltip align="center"> </el-table-column>
		</el-table>
		<el-pagination
			@size-change="_handleSizeChange"
			@current-change="_handleCurrentChange"
			:current-page="searchForm.pageNo"
			:page-sizes="[20, 40, 80, 100]"
			:page-size="searchForm.pageSize"
			style="text-align: right; margin-top: 40px"
			layout="total, sizes, prev, pager, next, jumper"
			:total="searchForm.totalNum"
		>
		</el-pagination>
		<el-dialog title="职级体系" top="5vh" center :visible.sync="dialogTableVisible" width="70%">
			<el-form :inline="true" class="demo-form-inline">
				<el-form-item class="formLabel" label="职族" style="margin-right: 20px">
					<el-input v-model="seletcForm.familyName" size="small" placeholder="请输入职族名称"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="职类" style="margin-right: 20px">
					<el-input v-model="seletcForm.className" size="small" placeholder="请输入职类名称"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="职位" style="margin-right: 20px">
					<el-input v-model="seletcForm.officeName" size="small" placeholder="请输入职位名称"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="岗位" style="margin-right: 20px">
					<el-input v-model="seletcForm.jobName" size="small" placeholder="请输入岗位名称"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" style="margin-right: 20px">
					<el-button @click="getFamilyList" icon="el-icon-search" size="small" type="primary">查询</el-button>
				</el-form-item>
			</el-form>
			<el-table :data="gridData" height="400" @current-change="handleCurrentChange" highlight-current-row>
				<el-table-column property="familyName" label="职族"></el-table-column>
				<el-table-column property="className" label="职类"></el-table-column>
				<el-table-column property="subclassName" label="职类子类"></el-table-column>
				<el-table-column property="officeName" label="职位"></el-table-column>
				<el-table-column property="officeCode" label="职级代码"></el-table-column>
				<el-table-column property="jobName" label="岗位"></el-table-column>
				<el-table-column property="level" label="职级"></el-table-column>
			</el-table>
			<div style="text-align: right; margin-top: 10px">
				<el-pagination
					background
					@current-change="handleSysPageChange"
					:current-page="seletcForm.pageNo"
					:page-size="seletcForm.pageSize"
					layout="total, prev, pager, next"
					:total="seletcForm.pageTotal"
				>
				</el-pagination>
			</div>
			<div slot="footer" class="dialog-footer" style="clear: both; text-align: center">
				<el-button @click="cancelSys">取 消</el-button>
				<el-button type="primary" @click="getSystem">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { bounsJobValueList, bounsJobValueSave } from '@/api/remuneration';
export default {
	props: { pageName: {} },
	data() {
		return {
			loading: false,
			editStatus: false,
			dialogTableVisible: false,
			showfamilyName: '',
			showclassName: '',
			showofficeName: '',
			showjobName: '',
			searchForm: {
				jobId: '',
				pageNo: 1,
				pageSize: 20,
				pageTotal: 0
			},
			seletcForm: {
				familyName: '',
				className: '',
				officeName: '',
				jobName: '',
				pageNo: 1,
				pageSize: 20,
				pageTotal: 0
			},
			gridData: [],
			list: [],
			jobList: [],
			vaList: [],
			currentRow: {}
		};
	},
	methods: {
		_clear() {
			this.searchForm.jobId = this.showfamilyName = this.showclassName = this.showofficeName = '';
		},
		_getList(page) {
			page ? (this.searchForm.pageNo = page) : '';
			this.vaList = [];
			bounsJobValueList(this.searchForm).then((res) => {
				this.list = res.list;
				for (const key in res.list) {
					this.vaList.push({ id: res.list[key].id, valueCoefficient: res.list[key].valueCoefficient });
				}
				this.searchForm.totalNum = res.totalNum;
			});
		},
		getJobList() {
			this.$axios
				.post('/v1/org/queryOfficeJob/list', {
					request: {
						type: '2', // 1：职位 2：岗位
						name: '',
						pageNo: 1,
						pageSize: 20000
					},
					sign: ''
				})
				.then((res) => {
					// console.log('res',res);
					this.jobList = res.list;
				});
		},
		_handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this._getList();
		},
		_handleCurrentChange(val) {
			this.searchForm.pageNo = val;
			this._getList();
		},
		_save() {
			let _flag = false;

			const _obj = {};

			const _list = [];

			for (const key in this.vaList) {
				if (this.list[key].valueCoefficient > 9) {
					this.$message.error('岗位价值系数不能大于9');
					return false;
				}
				if (this.vaList[key].valueCoefficient != this.list[key].valueCoefficient) {
					_flag = true;
					_list.push(this.list[key]);
				}
			}
			if (!_flag) {
				this.$message.error('岗位价值系数没有变化');
				return false;
			}
			_obj.jobValueList = _list;
			this.loading = true;
			bounsJobValueSave(_obj).then((res) => {
				this.loading = false;
				if (res.code == 0) {
					for (const key in this.list) {
						for (const keys in _list) {
							if (_list[keys].id == this.list[key].id) {
								this.list[key].valueCoefficient = this.vaList[key].valueCoefficient = _list[keys].valueCoefficient;
							}
						}
					}
					this._getList();
					this.editStatus = false;
					this.$message.success('保存成功');
				} else {
					this.$message.error(res.message);
				}
			});
		},
		_cancel() {
			for (const key in this.list) {
				this.list[key].valueCoefficient = this.vaList[key].valueCoefficient;
			}
			this.editStatus = false;
		},
		getFamilyList() {
			this.$axios.post('/v1/profession/system/list', { request: this.seletcForm }).then((res) => {
				this.seletcForm.pageTotal = res.totalNum;
				this.gridData = res.list;
			});
		},
		handleCurrentChange(val) {
			this.currentRow = val;
		},
		cancelSys() {
			this.currentRow = [];
			this.dialogTableVisible = false;
		},
		getSystem() {
			if (!this.currentRow) {
				this.$message.error('请选择职级体系信息');
				return;
			}
			this.showfamilyName = this.currentRow.familyName;
			this.showclassName = this.currentRow.className;
			this.showofficeName = this.currentRow.officeName;
			this.showjobName = this.currentRow.jobName;
			this.searchForm.jobId = this.currentRow.jobId;
			this.dialogTableVisible = false;
		},
		handleSysPageChange(val) {
			this.seletcForm.pageNo = val;
			this.getFamilyList();
		}
	},
	mounted() {
		this.getJobList();
		this._getList();
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
</style>
