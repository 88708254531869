<template>
	<div v-loading="loading">
		<el-form
			:model="searchForm"
			inline
			ref="form"
			class="searchForm"
			size="small"
			style="background-color: #fff; margin-bottom: 0px; padding-top: 20px; padding-bottom: 10px"
		>
			<el-row style="text-align: right; margin-bottom: 0px; float: right">
				<el-input v-model="search" size="small" placeholder="请输入组织名称" style="width: 300px; margin-right: 15px"> </el-input>
				<el-button type="primary" icon="el-icon-download" size="small" @click="_export" v-if="permissionControlBtns(pageName, 'Export')"
					>导出</el-button
				>
			</el-row>
			<el-form-item label="年份">
				<el-date-picker
					style="width: 160px"
					type="year"
					value-format="yyyy"
					format="yyyy"
					v-model="searchForm.year"
					placeholder="年份"
					:picker-options="pickerOptionsStart"
					clearable
				>
				</el-date-picker>
			</el-form-item>
			<!-- <el-form-item label="部门">
          <treeselect
            :props="depProps"
            :options="deps"
            :value="searchForm.department"
            :clearable="true"
            :accordion="true"
            :level="searchForm.department"
            @getValue="_getValue($event)"
            style="width: 160px;"/>
        </el-form-item> -->
			<el-button type="primary" icon="el-icon-search" style="margin-left: 5px" size="small" @click="_getList(1)">查询</el-button>
		</el-form>
		<el-table
			:data="tableData"
			ref="treeTable"
			style="width: 100%; margin-bottom: 20px"
			row-key="orgId"
			:tree-props="{ children: 'orgBonusSub', hasChildren: 'hasChildren' }"
		>
			<el-table-column label="组织名称" prop="orgName" align="center" width="280px"> </el-table-column>
			<el-table-column v-for="(col, i) in columns" :prop="col.prop" :key="i" :label="col.label" show-overflow-tooltip align="center">
				<!-- <template v-slot="{ row }" v-if="col.prop != 'orgStaffNumber'">
                {{row[col.prop] | rounding}}
            </template> -->
			</el-table-column>
		</el-table>
		<!-- <el-pagination
        @size-change="_handleSizeChange"
        @current-change="_handleCurrentChange"
        :current-page="searchForm.pageNo"
        :page-sizes="[20, 40, 80, 100]"
        :page-size="searchForm.pageSize"
        style="text-align:right;padding: 20px 0;background-color: #fff;"
        layout="total, sizes, prev, pager, next, jumper"
        :total="searchForm.totalNum">
    </el-pagination> -->
	</div>
</template>
<script>
// import treeselect from "@/components/treeSelect/treeSelect";
import { bonusOrgList } from '@/api/remuneration';
export default {
	props: { pageName: {} },
	// components : { treeselect },
	data() {
		return {
			searchForm: {
				year: '',
				orgId: '',
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			columns: [
				{ label: '组织系数', prop: 'orgRatio' },
				{ label: '组织绩效等级', prop: 'performanceScore' },
				{ label: '考核系数', prop: 'assessRatio' },
				{ label: '考核得分', prop: 'assessScore' },
				{ label: '奖金系数', prop: 'bonusSratio' },
				{ label: '奖金包', prop: 'bonusAmount' },
				{ label: '部门人数', prop: 'orgStaffNumber' }
			],
			deps: this.$store.state.setData.depsList,
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			loading: false,
			list: [],
			tableData: [],
			search: '',
			pickerOptionsStart: {
				disabledDate(time) {
					return time.getTime() > new Date(new Date().toLocaleDateString()).getTime();
				}
			}
		};
	},
	mounted() {
		this.searchForm.year = String(new Date().getFullYear());
		this._getList();
	},
	filters: {
		rounding(value) {
			return Number(value).toFixed(2);
		}
	},
	watch: {
		search: {
			handler(val) {
				if (val) {
					this.expendRows = [];
					this.tableData = this.searchData(this.list, val);
					this.expendRows.forEach((v) => {
						this.$refs.treeTable.toggleRowExpansion(v, true);
					});
				} else {
					this.tableData = this.list;
				}
			},
			immediate: true
		}
	},
	methods: {
		_getValue(eve) {
			this.searchForm.orgId = eve ? eve.id : '';
		},
		_getList(page) {
			page ? (this.searchForm.pageNo = page) : '';
			this.loading = true;
			bonusOrgList(this.searchForm).then((res) => {
				this.loading = false;
				this.list = res;
				this.tableData = res;
			});
		},
		_handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this._getList();
		},
		_handleCurrentChange(val) {
			this.searchForm.pageNo = val;
			this._getList();
		},
		_export() {
			const _obj = {
				year: this.searchForm.year,
				orgId: this.searchForm.orgId
			};

			const outStr = encodeURIComponent(JSON.stringify(_obj));

			const url = `/v1/bonus/org/list/export?params=${outStr}`;

			window.open(document.location.origin + url);
		},
		searchData(data, val) {
			const newData = [];

			data.forEach((v) => {
				if (v.children && v.children.length > 0) {
					let flag = false;

					this.expendRows.push(v);
					if (v.orgName && v.orgName.toLowerCase().match(new RegExp(val.toLowerCase()))) {
						flag = true;
						newData.push(v);
					}
					const children = this.searchData(v.children, val);
					const obj = {
						...v,
						children
					};

					if (children && children.length && !flag) {
						newData.push(obj);
					}
				} else if (v.orgName && v.orgName.toLowerCase().match(new RegExp(val.toLowerCase()))) {
					this.expendRows.push(v);
					newData.push(v);
				}
			});
			return newData;
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
</style>
