<template>
	<div v-loading="loading">
		<div class="box">
			<div class="box_title">组织价值系数配置</div>
			<el-form ref="searchForm" size="small" style="background-color: #fff; margin-bottom: 0px; padding-top: 0px; padding-bottom: 5px">
				<el-form-item label="年份">
					<el-date-picker
						v-model="searchForm.year"
						type="year"
						value-format="yyyy"
						placeholder="请选择对应年度"
						@change="_yearChange"
						:picker-options="pickerOptionsStart"
					></el-date-picker>
				</el-form-item>
			</el-form>
			<el-row :gutter="20" class="box_table_row" style="background-color: #fbfcff">
				<el-col :span="6" v-for="(item, index) in list" :key="index" class="box_table_rowS">
					<div class="box_table_row_item">
						<el-row class="box_table_title">
							<el-col :span="12" class="box_table_titleL">{{ item.name }}</el-col>
							<el-col :span="12" class="box_table_titleR">
								<el-link
									icon="el-icon-edit-outline"
									style="color: #fff; font-size: 18px"
									:underline="false"
									@click="_edit(item, index)"
									v-if="permissionControlBtns(pageName, 'Edit')"
								></el-link>
							</el-col>
						</el-row>
						<div class="box_table">
							<el-table
								stripe
								border
								:data="item.children"
								:row-style="tableRowStyle"
								:header-row-style="{ 'background-color': '#F0F8FF' }"
								@row-click="
									(row) => {
										_rowClick(row, index);
									}
								"
							>
								<el-table-column
									prop="orgName"
									:label="index == 0 ? '部门名称' : '名称'"
									show-overflow-tooltip
									align="center"
								></el-table-column>
								<el-table-column prop="valueCoefficient" label="组织系数" show-overflow-tooltip align="center">
									<template v-slot="{ row }">{{ row.valueCoefficient }}%</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
					<el-link
						v-if="(index != list.length - 1 && (index + 1) % 4 != 0) || (list.length >= 2 && index == 0)"
						icon="el-icon-arrow-right"
						type="primary"
						:underline="false"
						style="font-size: 20px"
					></el-link>
				</el-col>
			</el-row>
		</div>
		<el-dialog :visible.sync="dialogVisible" class="editDialog" center width="900px">
			<span slot="title" class="dialog-title">{{ `${currentRow.name}系数配置编辑` }}</span>
			<el-form :model="currentRow" ref="form" size="small" label-width="120px" style="max-height: 400px; overflow: auto; width: 100%">
				<div v-for="(item, index) in currentRow.children" :key="index">
					<el-form-item :label="`${item.orgName}系数`">
						<el-input
							style="width: 300px; margin-right: 100px"
							v-model="item.valueCoefficient"
							:disabled="currentRow.switch == item.orgId"
							@input="_inputTypes"
							onkeydown="value=value.replace(/[^\d^\.]/g,'')"
						>
							<template slot="append">%</template>
						</el-input>
						<!-- <el-radio v-model="currentRow.switch" :label="item.orgId">其他配置方式</el-radio> -->
						<!-- <el-checkbox v-model="item.switch">其他配置方式</el-checkbox> -->
						<el-switch
							:inactive-value="currentRow.switch == item.orgId"
							:active-value="currentRow.switch != item.orgId"
							active-text="其他配置方式"
							@change="
								(val) => {
									_changeSw(val, item.orgId);
								}
							"
						></el-switch>
					</el-form-item>
					<el-form-item v-show="currentRow.switch == item.orgId" :label="`${item.orgName}系数 =`">
						<el-row>
							<el-checkbox-group v-model="item.checkList" size="mini" @change="_inputTypes">
								<el-checkbox v-for="(child, i) in item._configContent" :key="i" :label="child.orgId">
									<span style="display: inline-block; width: 70px">{{ child.orgName }}</span>
									<el-input
										onkeydown="value=value.replace(/[^\d^\.]/g,'')"
										style="width: 120px; margin-top: -5px"
										v-model="child.precentage"
										@input="_inputTypes"
									>
										<template slot="append">%</template>
									</el-input>
									<span v-if="i != item._configContent.length - 1" style="margin-left: 10px; margin-right: -20px">+</span>
								</el-checkbox>
							</el-checkbox-group>
						</el-row>
					</el-form-item>
				</div>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="_dialogVisible">取 消</el-button>
				<el-button type="primary" @click="_save">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog :visible.sync="dialogVisible2" width="40%">
			<span slot="title" class="dialog-title">{{ `${currentRow.name}系数配置编辑` }}</span>
			<el-form
				:model="currentRow"
				ref="form"
				label-width="120px"
				size="small"
				style="max-height: 400px; margin-bottom: 20px; overflow: auto; width: 100%"
			>
				<el-form-item v-for="(item, index) in currentRow.children" :key="index" :label="item.orgName">
					<el-input
						onkeyup="value=value.replace(/[^\d^\.]/g,'')"
						style="width: 300px; margin-right: 20px"
						v-model="item.valueCoefficient"
						:disabled="item.switch == 1"
					>
						<template slot="append">%</template>
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible2 = false">取 消</el-button>
				<el-button type="primary" @click="_save">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import { bounsOrgValueList, bounsOrgValueConfig } from '@/api/remuneration';
export default {
	props: { pageName: {} },
	data() {
		return {
			loading: false,
			dialogVisible: false,
			dialogVisible2: false,
			searchForm: {
				classId: '',
				year: String(new Date().getFullYear())
			},
			indexVal: 1,
			editIndex: 0,
			list: [],
			currentRow: {},
			currentIndex: 0,
			checkOrg: [],
			pickerOptionsStart: {
				disabledDate(time) {
					return time.getTime() > new Date(new Date().toLocaleDateString()).getTime();
				}
			}
		};
	},
	methods: {
		_getList(flag) {
			bounsOrgValueList(this.searchForm).then((res) => {
				if (res.length > 0) {
					const _index = this.indexVal;

					if (_index == 1) {
						const _res = [...res];

						for (const key in _res) {
							_res[key]._configContent = [];
							_res[key].checkList = [];
							for (const keys in _res) {
								if (_res[keys].orgId != _res[key].orgId) {
									const _val = this._returnPrecentage(_res[key].configContent, _res[keys].orgId);

									_res[key]._configContent.push({
										orgName: _res[keys].orgName,
										orgId: _res[keys].orgId,
										precentage: _val
									});
								}
							}
							_res[key].valueCoefficient = _res[key].valueCoefficient * 100;
						}
						if (flag) {
							this.$set(this.list, 0, {
								name: `${_index}级部门`,
								children: _res
							});
						} else {
							this.list.push({ name: `${_index}级部门`, children: _res });
						}
					} else if (flag) {
						const _res = [...res];

						for (const key in _res) {
							_res[key]._configContent = [];
							_res[key].checkList = [];
							for (const keys in _res) {
								if (_res[keys].orgId != _res[key].orgId) {
									const _val = this._returnPrecentage(_res[key].configContent, _res[keys].orgId);

									_res[key]._configContent.push({
										orgName: _res[keys].orgName,
										orgId: _res[keys].orgId,
										precentage: _val
									});
								}
							}
							_res[key].valueCoefficient = _res[key].valueCoefficient * 100;
						}
						this.$set(this.list, 0, {
							name: `${_index}级部门`,
							children: _res
						});
					} else {
						const _list = [...res];

						for (const key in _list) {
							_list[key].valueCoefficient = Number(_list[key].valueCoefficient) * 100;
						}
						this.list.push({ name: `${_index}级部门`, children: _list });
					}
				} else {
					this.$message.warning('该部门下没有子部门');
				}
			});
		},
		_save() {
			const _obj = {};

			_obj.year = this.searchForm.year;
			if (this.editIndex == 0) {
				const _list = JSON.parse(JSON.stringify(this.currentRow.children));

				let _val = 0;

				for (const key in _list) {
					const _valList = [];

					for (const _val of _list[key].checkList) {
						for (const keys in _list[key]._configContent) {
							if (_list[key]._configContent[keys].orgId == _val) {
								if (_list[key].orgId == this.currentRow.switch && _list[key]._configContent[keys].precentage == '') {
									this.$message.error(`请补全${_list[key].orgName}的系数`);
									return false;
								}
								_list[key]._configContent[keys].precentage = _list[key]._configContent[keys].precentage / 100;
								_valList.push(_list[key]._configContent[keys]);
							}
						}
					}
					_val += Number(_list[key].valueCoefficient);
					_list[key].configContent = JSON.stringify(_valList);
					_list[key].valueCoefficient = _list[key].valueCoefficient / 100;
				}
				if (_val != 100) {
					this.$message.error('配置的部门比例之和为1');
					return false;
				}
				_obj.orgValueConfigList = _list;
			} else {
				_obj.orgValueConfigList = JSON.parse(JSON.stringify(this.currentRow.children));
				let _val = 0;

				for (const key in _obj.orgValueConfigList) {
					_val = parseFloat(_val + Number(_obj.orgValueConfigList[key].valueCoefficient));
					_obj.orgValueConfigList[key].valueCoefficient = _obj.orgValueConfigList[key].valueCoefficient / 100;

					if (!_obj.orgValueConfigList[key].valueCoefficient) {
						this.$message.error(`请补全${_obj.orgValueConfigList[key].orgName}的系数`);
						return false;
					}
				}
				if (_val != 100) {
					this.$message.error('配置的部门比例之和为1');
					return false;
				}
			}
			this.loading = true;
			bounsOrgValueConfig(_obj).then((res) => {
				this.loading = false;
				if (res.code == 0) {
					this.$message.success('修改成功');
					this.dialogVisible = false;
					this.dialogVisible2 = false;
					this.currentRow = {};
					if (this.editIndex != 0) {
						const _iist = [...this.list[this.editIndex].children];

						for (const key in _obj.orgValueConfigList) {
							_iist[key].valueCoefficient = _obj.orgValueConfigList[key].valueCoefficient * 100;
						}
						this.list[this.editIndex].children = [..._iist];
						this.$forceUpdate();
					} else {
						this.searchForm.classId = '';
						this._getList(true);
					}
				}
			});
		},
		_edit(item, index) {
			this.currentRow = {};
			this.currentRow = JSON.parse(JSON.stringify(item));
			this.editIndex = index;
			index == 0 ? (this.dialogVisible = true) : (this.dialogVisible2 = true);
		},
		_rowClick(row, i) {
			this.currentRow = row;
			this.currentIndex = i;
			this.searchForm.classId = row.orgId;
			if (i + 1 <= this.indexVal && this.indexVal != 1) {
				this.list = this.list.slice(0, i + 1);
				this.indexVal = i + 1;
				this.indexVal += 1;
			} else {
				this.indexVal += 1;
			}
			this._getList();
		},
		_dialogVisible() {
			this.dialogVisible = false;
			this.currentRow = {};
		},
		_yearChange(val) {
			if (!val) {
				this.$message.error('请选择年度');
			} else {
				this.searchForm.classId = '';
				this.searchForm.year = val;
				this.list = [];
				this.indexVal = 1;
				this._getList();
			}
		},
		_returnPrecentage(list, orgId) {
			if (list) {
				const _list = JSON.parse(JSON.parse(JSON.stringify(list)));

				for (const key in _list) {
					if (_list[key].orgId == orgId) {
						return _list[key].precentage * 100;
					}
				}
				return '0';
			}
			return '0';
		},
		_checkStatus(listIndex, orgId, orgId2) {
			const _list = [...this.currentRow.children];

			for (const key in _list) {
				// if(listIndex != key){
				const _l = _list[key].checkList;
				const _val = _list[key].orgId;

				for (const keys in _l) {
					if (_l[keys] == orgId && _val == orgId2) {
						return false;
					}
				}
				// }
			}
			return true;
		},
		_inputTypes() {
			if (!this.currentRow.switch) {
				for (const key in this.currentRow.children) {
					this.currentRow.children[key].checkList = [];
				}
			}
			let _val = 0;

			const _list = [...this.currentRow.children];

			let _item = '';

			let _f = false;

			for (const i in _list) {
				if (_list[i].checkList.length >= 1) {
					_item = i;
				}
				for (const key in _list[i]._configContent) {
					if (_list[i].checkList.length >= 1) {
						_f = true;
						for (const val of _list[i].checkList) {
							for (const ss in this.currentRow.children) {
								if (
									this.currentRow.children[ss].orgId == _list[i]._configContent[key].orgId &&
									val == _list[i]._configContent[key].orgId
								) {
									_val += Number(
										((_list[i]._configContent[key].precentage / 100) * this.currentRow.children[ss].valueCoefficient).toFixed(2)
									);
								}
							}
						}
					}
				}
			}
			if (_f) {
				this.currentRow.children[_item].valueCoefficient = _val;
				this.$forceUpdate();
			}
		},
		_changeSw(val, orgId) {
			if (this.currentRow.switch == orgId) {
				this.currentRow.switch = '';
			} else {
				for (const key in this.currentRow.children) {
					this.currentRow.children[key].checkList = [];
					for (const keys in this.currentRow.children[key]._configContent) {
						this.currentRow.children[key]._configContent[keys].precentage = 0;
					}
				}
				this.currentRow.switch = orgId;
			}
			this.$forceUpdate();
		},
		tableRowStyle() {
			return 'cursor: pointer;';
		}
	},
	mounted() {
		this.searchForm.year = String(new Date().getFullYear());
		this._getList();
	},
	watch: {
		dialogVisible(newVal) {
			if (newVal == false) {
				this.currentRow = {};
			}
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
.box {
	padding: 20px;
	.box_title {
		margin-bottom: 20px;
		&:before {
			display: inline-block;
			width: 8px;
			height: 20px;
			background: #498df0;
			content: ' ';
			border-radius: 5px;
			vertical-align: text-bottom;
			margin-right: 8px;
		}
	}
	.box_table_row {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		.box_table_rowS {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 30px;
			.box_table_row_item {
				flex: 1;
				background-color: #f8f9fc;
				.box_table_title {
					background-color: #498df0;
					color: #fff;
					padding: 10px;
					margin-bottom: 10px;
					.box_table_titleR {
						text-align: right;
					}
				}
				.box_table {
					padding: 10px 5px;
				}
			}
		}
	}
}
.editDialog {
	::v-deep.el-dialog__header {
		// border-bottom: 1px solid #eaedf1;
		text-align: center;
	}
	.dialog-title {
		font-size: 18px;
		color: #424754;
		letter-spacing: 0;
		line-height: 24px;
	}
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
</style>
