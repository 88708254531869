<template>
	<div v-loading="loading">
		<el-row style="text-align: right"> </el-row>
		<el-form
			:model="searchForm"
			inline
			ref="form"
			class="searchForm"
			size="small"
			style="background-color: #fff; margin-bottom: 0px; padding-top: 20px; padding-bottom: 10px"
		>
			<el-form-item label="年份">
				<el-date-picker
					style="width: 160px"
					type="year"
					value-format="yyyy"
					format="yyyy"
					v-model="searchForm.year"
					placeholder="年份"
					:picker-options="pickerOptionsStart"
					clearable
				>
				</el-date-picker>
			</el-form-item>
			<el-form-item label="部门">
				<treeselect
					:props="depProps"
					:options="deps"
					:value="searchForm.department"
					:clearable="true"
					:accordion="true"
					:level="searchForm.department"
					@getValue="_getValue($event)"
					style="width: 160px"
				/>
			</el-form-item>
			<el-form-item label="姓名">
				<el-input v-model="searchForm.staffName" placeholder="输入姓名" style="width: 160px" clearable />
			</el-form-item>
			<el-button type="primary" icon="el-icon-search" style="margin-left: 5px" size="small" @click="_getList(1)">查询</el-button>
			<div style="float: right">
				<el-button type="primary" icon="el-icon-download" size="small" @click="_export" v-if="permissionControlBtns(pageName, 'Export')"
					>导出</el-button
				>
			</div>
		</el-form>
		<el-table :data="showList" border>
			<!--<el-table-column
            fixed
            type="index"
            label="序号"
            show-overflow-tooltip
            width="100"
            align="center">
             <template v-slot="{ row }">
                {{row.index}}
            </template>
        </el-table-column> -->
			<el-table-column
				v-for="(col, i) in columns"
				:prop="col.prop"
				:key="i"
				:fixed="col.prop === 'staffName' || col.prop === 'orgName'"
				:label="col.label"
				show-overflow-tooltip
				width="150"
				align="center"
			>
				<!-- <template v-slot="{ row }" v-if="col.prop != 'staffName' && col.prop != 'orgName'&& col.prop != 'officeName' && col.prop != 'adjustBonusAmount'">
                {{row[col.prop] | rounding}}
            </template> -->
				<template v-slot="{ row }" v-if="col.prop == 'adjustBonusAmount'">
					{{ `${(row.adjustBonusAmount >= 0 ? '+' : '') + row.adjustBonusAmount}` }}
				</template>
				<template v-slot="{ row }" v-else-if="col.prop == 'remark'">
					<div class="moryeRemark">
						{{ row.remark }}
					</div>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			@size-change="_handleSizeChange"
			@current-change="_handleCurrentChange"
			:current-page="searchForm.pageNo"
			:page-sizes="[20, 40, 80, 100]"
			:page-size="searchForm.pageSize"
			style="text-align: right; margin-top: 40px"
			layout="total, sizes, prev, pager, next, jumper"
			:total="searchForm.totalNum"
		>
		</el-pagination>
	</div>
</template>
<script>
import treeselect from '@/components/treeSelect/treeSelect';
import { bonusStaffList } from '@/api/remuneration';
export default {
	props: { pageName: {} },
	components: { treeselect },
	data() {
		return {
			searchForm: {
				year: '',
				orgId: '',
				staffName: '',
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			deps: this.$store.state.setData.depsList,
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			loading: false,
			showList: [],
			columns: [
				{ label: '姓名', prop: 'staffName' },
				{ label: '部门', prop: 'orgName' },
				// {label : '部门组织系数' , prop : 'orgRatio' },
				// {label : '部门考核系数' , prop : 'assessRatio' },
				{ label: '岗位', prop: 'jobName' },
				{ label: '岗位价值系数', prop: 'jobRatio' },
				{ label: '调整后岗位价值系数', prop: 'adjustJobRatio' },
				{ label: '个人绩效等级', prop: 'performanceScore' },
				{ label: '个人考核系数', prop: 'userAssessRatio' },
				{ label: '个人考核得分', prop: 'assessScore' },
				{ label: '个人奖金系数', prop: 'bonusSratio' },
				{ label: '预测年终奖', prop: 'predictionBonusAmount' },
				{ label: '调整奖金', prop: 'adjustBonusAmount' },
				{ label: '实发年终奖金', prop: 'bonusAmount' },
				{ label: '调整原因', prop: 'remark' }
			],
			list: [],
			labels: [],
			setList: {},
			pickerOptionsStart: {
				disabledDate(time) {
					return time.getTime() > new Date(new Date().toLocaleDateString()).getTime();
				}
			}
		};
	},
	methods: {
		_getValue(eve) {
			this.searchForm.orgId = eve ? eve.id : '';
		},
		_getList(page) {
			page ? (this.searchForm.pageNo = page) : '';
			this.loading = true;
			bonusStaffList(this.searchForm).then((res) => {
				this.loading = false;
				this.searchForm.totalNum = res.totalNum;
				this.list = res.list;
				this.showList = res.list;
				// let _list = [];
				// this.labels = [...new Set(res.list.map(item => item.a))];
				// for(let val of this.labels){
				//     let _l = [];
				//     for(let key in this.list){
				//         if(this.list[key].a == val){
				//             this.list[key].index = Number(key)+1;
				//             _l.push(this.list[key]);
				//         }
				//     }
				//     _l[0].type =1;
				//     _l[0].rowLength = _l.length;
				//     const _obj = {
				//         index : '部门合计',
				//         orgRatio : this._returnSummaries(_l,'orgRatio'),
				//         assessScore : this._returnSummaries(_l,'assessScore'),
				//         bonusSratio : this._returnSummaries(_l,'bonusSratio'),
				//         predictionBonusAmount : this._returnSummaries(_l,'predictionBonusAmount'),
				//         adjustBonusAmount : this._returnSummaries(_l,'adjustBonusAmount',true),
				//         bonusAmount : this._returnSummaries(_l,'bonusAmount'),
				//         sum : 1
				//     }
				//     _list = _list.concat(_l);
				//     _list.push(_obj)
				// }
				// this.showList = _list;
			});
		},
		_handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this._getList();
		},
		_handleCurrentChange(val) {
			this.searchForm.pageNo = val;
			this._getList();
		},
		objectSpanMethod({ row, rowIndex, columnIndex }) {
			if (columnIndex <= 1) {
				if (rowIndex === 0) {
					return [this.showList.length, 1];
				}
				return [0, 0];
			}
			if (columnIndex > 1 && columnIndex <= 7) {
				if (row.type) {
					return [row.rowLength, 1];
				}
				if (!row.sum) {
					return [0, 0];
				}
			}
		},
		_export() {
			const _obj = {
				year: this.searchForm.year,
				orgId: this.searchForm.orgId,
				staffName: this.searchForm.staffName
			};

			const outStr = encodeURIComponent(JSON.stringify(_obj));

			const url = `/v1/bonus/staff/list/export?params=${outStr}`;

			window.open(document.location.origin + url);
		},
		getSummaries(param) {
			const { columns } = param;
			const sums = [];

			columns.forEach((column, index) => {
				if (index === 0 || index === 1) {
					sums[index] = '';
					return;
				}
				if (index === 2) {
					sums[index] = '总计';
					return;
				}
				const values = this.list.map((item) => Number(item[column.property]));

				if (index == 3) {
					sums[index] = `${this._returnSummaries(this.setList, 'b')}%`;
					return;
				}
				if (index == 5) {
					sums[index] = this._returnSummaries(this.setList, 'd');
					return;
				}
				if (index == 7) {
					sums[index] = this._returnSummaries(this.setList, 'f');
					return;
				}
				if (index >= 16) {
					sums[index] = values.reduce((prev, curr, i) => {
						const value = Number(curr);

						if (index == 17) {
							if (!isNaN(value)) {
								if (i == values.length - 1) {
									const _val = prev + curr;

									return _val > 0 ? `+${_val}` : _val;
								}
								return prev + curr;
							}
							return prev;
						}
						if (!isNaN(value)) {
							return prev + curr;
						}
						return prev;
					}, 0);
				}
			});
			return sums;
		},
		_returnSummaries(list, prop, flag) {
			const _values = [...list].map((item) => Number(item[prop]));
			const _returnValue = _values.reduce((prev, curr) => prev + curr, 0);

			return _returnValue >= 0 ? _returnValue : flag ? `-${_returnValue}` : _returnValue;
		}
	},
	mounted() {
		this.searchForm.year = String(new Date().getFullYear());
		this._getList();
	},
	filters: {
		rounding(value) {
			return Number(value).toFixed(2);
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
.moryeRemark {
	width: 128px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
}
</style>
