import { post } from "./api";
/*
 * @Author: 卢均锐morye
 * @Date: 2020-03-25 21:32:25
 * @LastEditTime: 2020-04-28 16:15:40
 * @modules: 薪酬管理
 */
/**
 * @description: 年终奖管理部门
 * @param {type} 
 * @return: 
 */
export const bonusOrgList = (params) => post('/v1/bonus/org/list', { request: params });
/**
 * @description: 年终奖管理个人
 * @param {type} 
 * @return: 
 */
export const bonusStaffList = (params) => post('/v1/bonus/staff/list', { request: params });
/**
 * @description: 部门价值系数分配
 * @param {type} 
 * @return: 
 */
export const bounsOrgValueConfig = (params) => post('/v1/bonus/org/value/config', { request: params });
/**
 * @description: 部门价值系数查询
 * @param {type} 
 * @return: 
 */
export const bounsOrgValueList = (params) => post('/v1/bonus/org/value/list', { request: params });
/**
 * @description: 岗位价值系数配置列表
 * @param {type} 
 * @return: 
 */
export const bounsJobValueList = (params) => post('/v1/bonus/job/value/list', { request: params });
/**
 * @description: 岗位价值系数配置保存
 * @param {type} 
 * @return: 
 */
export const bounsJobValueSave = (params) => post('/v1/bonus/job/value/save', { request: params });
/**
 * @description: 绩效系数配置列表
 * @param {type} 
 * @return: 
 */
export const bounsPerformanceValueList = (params) => post('/v1/bonus/performance/value/list', { request: params });
/**
 * @description: 绩效系数配置
 * @param {type} 
 * @return: 
 */
export const bounsPerformanceValueSave = (params) => post('/v1/bonus/performance/value/save', { request: params });
/**
 * @description: 奖金计算配置详情
 * @param {type} 
 * @return: 
 */
export const bounsBounsCalcuationConfigDetail = (params) => post('/v1/bonus/calculation/config/detail', { request: params });
/**
 * @description: 奖金计算配置提交
 * @param {type} 
 * @return: 
 */
export const bounsCalcuationConfigSubmit = (params) => post('/v1/bonus/calculation/config/submit', { request: params });
/**
 * @description: 发放奖金调整
 * @param {type} 
 * @return: 
 */
export const bounsAdjust = (params) => post('/v1/bonus/adjust', { request: params });
/**
 * @description: 年终奖发放
 * @param {type} 
 * @return: 
 */
export const bounsGrant = (params) => post('/v1/bonus/grant', { request: params });
/**
 * @description: 奖金确认计算
 * @param {type} 
 * @return: 
 */
export const bounsConfirmCalculation = (params) => post('/v1/bonus/confirm/calculation', { request: params });
/**
 * @description: 企业成本设置查询
 * @param {type} 
 * @return: 
 */
export const costConfigList = (params) => post('/v1/enterprise/cost/config/list', { request: params });

//当月工资列表
export const payrollMonthList = (params) => post('/v1/payroll/month/list', { request: params });
//工资设置查询
export const payrollConfigList = (params) => post('/v1/payroll/config/list', { request: params });
//发放工资条
export const payrollGrant = (params) => post('/v1/payroll/grant', { request: params });
//员工工资详情
export const payrollDetail = (params) => post('/v1/payroll/staff/detail', { request: params });
//历史工资列表
export const historyList = (params) => post('/v1/payroll/history/list', { request: params });
//工资设置保存
export const payrollConfigSave = (params) => post('/v1/payroll/config/save', { request: params });
//工资设置删除
export const payrollConfigDelete = (params) => post('/v1/payroll/config/delete', { request: params });
//薪酬分析
export const payrollAnalysis = (params) => post('/v1/payroll/analysis', { request: params });
//员工端-工资条列表
export const payrollStaffList = (params) => post('/v1/payroll/staff/list', { request: params });
// 员工端-年终奖金列表
export const payrollBonusStaffList = (params) => post(' /v1/payroll/bonus/staff/list', { request: params });
//员工端-员工工资详情
export const payrollStaffDetails = (params) => post('/v1/payroll/staff/salary/details', { request: params });
//工资设置查询(用来给其它页面调用)
export const payrollConfigDetail = (params) => post('/v1/payroll/config/detail', { request: params });
//员工工资修改
export const payrollUpdate = (params) => post('/v1/payroll/update', { request: params });
//查看公司工资是否发放过
export const grantWhether = (params) => post('/v1/payroll/whether/grant', { request: params });
//清空公司员工工资记录
export const grantEmpty = (params) => post('/v1/payroll/grant/empty', { request: params });

//保存薪酬带宽配置
export const saveSalaryRangeConfig= (params) => post('/v1/payroll/range/config/save', { request: params });
//获取薪酬带宽列表
export const getSalaryRangeConfigList= (params) => post('/v1/payroll/range/config/list', { request: params });
//保存薪酬带宽配置
export const deleteSalaryRangeConfig= (params) => post('/v1/payroll/range/config/delete', { request: params });
//薪酬带宽配置视图
export const salaryRangeConfigView= (params) => post('/v1/payroll/range/config/view', { request: params });

//企业成本设置保存
export const enterPrisecostConfigSave = (params) => post('/v1/enterprise/cost/config/save', { request: params });
//企业成本设置删除
export const enterPrisecostConfigDel = (params) => post('/v1/enterprise/cost/config/delete', { request: params });
//企业成本列表
export const enterPrisecostList = (params) => post('/v1/enterprise/cost/list', { request: params });
//企业成本详情
export const PrisecostInfo = (params) => post('/v1/enterprise/cost/detail', { request: params });
//企业成本修改
export const PrisecostUpdate= (params) => post('/v1/enterprise/cost/update', { request: params });

